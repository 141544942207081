import { NativeStackHeaderProps } from '@react-navigation/native-stack'
import {
  useGateValue,
  useStatsigClient,
  useStatsigUser,
} from '@statsig/expo-bindings'
import * as QuickActions from 'expo-quick-actions'
import { RouterAction, useQuickActionRouting } from 'expo-quick-actions/router'
import { Redirect, Stack } from 'expo-router'
import * as SplashScreen from 'expo-splash-screen'
import { useEffect, useMemo } from 'react'
import { Platform } from 'react-native'
import { useStyles } from 'react-native-unistyles'
import { Feature } from '../../../constants'
import Header from '../../components/navigation/header/Header'
import useAccessToken from '../../hooks/useAccessToken'
import useRegisterPushNotifications from '../../hooks/useRegisterPushNotifications'
import OrganizationService from '../../services/organizationService'
import { useAppStore } from '../../store/useAppStore'
import * as datadog from '../../utils/datadog/datadog'
import purchases from '../../utils/purchases/purchases'
import trpc from '../../utils/trpc'

SplashScreen.preventAutoHideAsync()

export const unstable_settings = {
  initialRouteName: '(tabs)',
}

const HeaderComponent = (props: NativeStackHeaderProps) => {
  return <Header {...props} />
}

const InnerAppLayout = () => {
  // Enable linking to the `href` param when a quick action is used.
  useQuickActionRouting()

  const { theme } = useStyles()

  const auth = useAppStore.use.auth()
  const { user: statsigUser, updateUserSync } = useStatsigUser()
  const hasDatadogRUM = useGateValue(Feature.DatadogRUM)

  const accessToken = useAccessToken()
  const setAuth = useAppStore.use.setAuth()

  useEffect(() => {
    if (accessToken && accessToken !== auth.accessToken) {
      setAuth({ accessToken, isLoggedIn: auth.isLoggedIn })
    }
  }, [accessToken, auth.accessToken, auth.isLoggedIn, setAuth])

  const { currentOrganization } = OrganizationService.useCurrentOrganization()

  const currentUserQuery = trpc.user.current.useQuery({
    organizationId: currentOrganization?.id,
  })

  const { client: statsigClient } = useStatsigClient()

  useRegisterPushNotifications()

  useMemo(async () => {
    if (hasDatadogRUM) {
      await datadog.initialize()

      // await datadog.enableSessionReplay()
    } else {
      datadog.stop()
    }

    statsigClient.on('gate_evaluation', (event) => {
      datadog.evaluateFeature(event.gate.name, event.gate.value)
    })
  }, [hasDatadogRUM, statsigClient])

  useEffect(() => {
    // Now you can configure your quick actions to link places (including externally):
    QuickActions.setItems<RouterAction>([
      {
        title: 'Search',
        icon: Platform.OS === 'ios' ? 'search' : 'shortcut_search',
        id: '0',
        params: { href: '/fosters/search' },
      },
    ])
  }, [])

  useEffect(() => {
    async function initialize() {
      if (!currentOrganization) {
        return
      }

      purchases.configure(currentOrganization.id, currentOrganization.name)
    }

    initialize()
  }, [currentOrganization, currentOrganization?.id, currentOrganization?.name])

  useEffect(() => {
    const currentUser = currentUserQuery.data
    if (!currentUser) {
      return
    }

    async function paywall() {
      if (!currentOrganization) {
        return
      }

      if (await purchases.isConfigured()) {
        await purchases.logIn(currentOrganization.id)

        const ci = await purchases.getCustomerInfo()

        currentOrganization.subscriptionActive =
          !!ci?.entitlements.all?.premium?.isActive

        // setCurrentOrganization({ organization: currentOrganization })
      }
    }

    paywall()

    updateUserSync({
      ...statsigUser,
      custom: {
        ...statsigUser.custom,
        organizationId: currentOrganization?.id || '',
      },
      userID: currentUser.id,
      email: currentUser.email,
    })

    datadog.setUser({
      id: currentUser.id,
      email: currentUser.email,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
    })

    // disable the lint rule here specifically because statsigUser and updateUserSync change in this hook,
    // and we want to avoid a loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentOrganization?.id,
    currentUserQuery.data?.id,
    currentUserQuery.data?.email,
  ])

  if (auth.isLoggedIn === undefined) {
    return null
  }

  if (auth.isLoggedIn === false) {
    return <Redirect href="/login" />
  }

  return (
    <Stack
      screenOptions={{
        headerShown: true,
        header: HeaderComponent,
        contentStyle: {
          backgroundColor: theme.colors.background,
        },
      }}
    >
      <Stack.Screen name="(tabs)" />
    </Stack>
  )
}

export default InnerAppLayout
