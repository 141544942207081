import { useGateValue } from '@statsig/expo-bindings'
import { useRouter } from 'expo-router'
import { useEffect, useRef, useState } from 'react'
import { TextInput as RNTextInput, View } from 'react-native'
import {
  Button,
  Card,
  Divider,
  Text,
  TextInput,
  useTheme,
} from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { Feature } from '../../constants'
import Logo from '../components/navigation/Logo'
import SlideTransition from '../components/shared/animations/SlideTransition'
import Snackbar from '../components/snackbar/Snackbar'
import { useAppStore } from '../store/useAppStore'
import {
  clearLoginAttemptInfo,
  getCode,
  getLoginAttemptInfo,
  passwordSignIn,
  validateOtp,
} from '../utils/auth/auth'
import log from '../utils/datadog/log/log'
import { logError } from '../utils/log'

const Login = () => {
  const hasPasswordAuth = useGateValue(Feature.PasswordAuth)

  const [email, onEmailChange] = useState('')
  const [password, onPasswordChange] = useState('')
  const [otp, onOtpChange] = useState('')
  const [otpAlreadySent, setOtpAlreadySent] = useState(false)
  const [otpError, setOtpError] = useState('')
  const [isSigningIn, setIsSigningIn] = useState(false)
  const [isValidatingOtp, setIsValidatingOtp] = useState(false)

  const router = useRouter()
  const theme = useTheme()
  const setAuth = useAppStore.use.setAuth()
  const { styles } = useStyles(stylesheet)

  const emailInputRef = useRef<RNTextInput>(null)
  const otpInputRef = useRef<RNTextInput>(null)

  useEffect(() => {
    if (!otpAlreadySent) {
      otpInputRef.current?.blur()
      emailInputRef.current?.focus()
    } else {
      emailInputRef.current?.blur()
      otpInputRef.current?.focus()
    }
  }, [otpAlreadySent])

  async function hasInitialOTPBeenSent() {
    const loginAttemptInfo = await getLoginAttemptInfo()
    setOtpAlreadySent(loginAttemptInfo !== undefined)
  }

  async function handleOTPInput() {
    setIsValidatingOtp(true)
    try {
      const response = await validateOtp(otp)

      if (response.status === 'OK') {
        otpInputRef.current?.blur()
        await clearLoginAttemptInfo()
        if (
          response.createdNewRecipeUser &&
          response.user.loginMethods.length === 1
        ) {
          // user sign up success
        } else {
          // user sign in success
        }

        setAuth({
          accessToken: undefined,
          isLoggedIn: true,
        })
        await clearLoginAttemptInfo()
        router.replace('/')
      } else if (response.status === 'INCORRECT_USER_INPUT_CODE_ERROR') {
        log.warn('Incorrect OTP entered')
        setOtpError('The code you entered is invalid. Please try again.')
      } else if (response.status === 'EXPIRED_USER_INPUT_CODE_ERROR') {
        log.warn('Expired OTP entered')
        setOtpError('The code you entered is expired. Please log in again.')
      } else {
        log.error(
          'error validating OTP or the user tried the wrong flow too many times'
        )
        await clearLoginAttemptInfo()
        Snackbar.error('Login failed. Please try again')
        // Navigate to the root, which will trip the login need, and re-render the login screen fresh
        router.replace('/')
      }
    } catch (err: any) {
      // this may be a custom error message sent from the API by you.
      if (err.isSuperTokensGeneralError === true) {
        // TODO - handle custom error message?
        log.error(err.message, err)
      } else {
        log.error(err.message, err)
        Snackbar.error('Oops! Something went wrong.')
      }
    } finally {
      setIsValidatingOtp(false)
    }
  }

  async function passwordLoginClicked() {
    try {
      const response = await passwordSignIn(email, password)

      if (response.status === 'FIELD_ERROR') {
        response.formFields.forEach((formField) => {
          if (formField.id === 'email') {
            // Email validation failed (for example incorrect email syntax).
            log.error(formField.error)
            Snackbar.error('Login failed. Please try again')
          }
        })
      } else if (response.status === 'WRONG_CREDENTIALS_ERROR') {
        Snackbar.error('Email password combination is incorrect.')
      } else if (response.status === 'SIGN_IN_NOT_ALLOWED') {
        // the reason string is a user friendly message
        // about what went wrong. It can also contain a support code which users
        // can tell you so you know why their sign in was not allowed.
        log.error(response.reason)
        Snackbar.error('Login failed. Please try again')
      } else {
        // sign in successful. The session tokens are automatically handled by
        // the frontend SDK.
        setAuth({
          accessToken: undefined,
          isLoggedIn: true,
        })
        router.replace('/')
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        log.error(err.message, err)
      } else {
        log.error(err.message, err)
        Snackbar.error('Oops! Something went wrong.')
      }
    }
  }

  async function sendMagicLink() {
    setIsSigningIn(true)
    try {
      const response = await getCode(email)

      if (response.status === 'SIGN_IN_UP_NOT_ALLOWED') {
        log.warn('sign in/up not allowed')
      } else {
        emailInputRef.current?.blur()
        setOtpAlreadySent(true)
      }
    } catch (err: any) {
      // this may be a custom error message sent from the API by you,
      if (err.isSuperTokensGeneralError === true) {
        // TODO - handle custom error message?
        log.error(err.message, err)
      } else {
        Snackbar.error('Oops! Something went wrong.')
      }
    } finally {
      setIsSigningIn(false)
    }
  }

  useEffect(() => {
    hasInitialOTPBeenSent().catch(logError)
  }, [])

  return (
    <View style={styles.container}>
      <Card>
        <View style={styles.cardContainer}>
          <Logo style={styles.logoStyle} />
          <Divider />
          <View style={styles.innerCard}>
            <SlideTransition
              active={otpAlreadySent}
              direction="left"
              isPrimary={true}
              style={styles.transitionContainer}
            >
              <Text
                style={{ color: theme.colors.primary }}
                variant="headlineMedium"
              >
                Sign In
              </Text>
              <Text>Please sign in to continue.</Text>

              <TextInput
                accessibilityHint="Enter your email address"
                accessibilityLabel="Email Address"
                autoComplete="email"
                inputMode="email"
                label="Enter your email address"
                mode="outlined"
                onChangeText={onEmailChange}
                ref={emailInputRef}
                testID="magic-link-input"
                textContentType="emailAddress"
                value={email}
              />
              {hasPasswordAuth ? (
                <>
                  <TextInput
                    accessibilityHint="Enter your password"
                    accessibilityLabel="Password"
                    label="Enter your password"
                    mode="outlined"
                    onChangeText={onPasswordChange}
                    secureTextEntry
                    style={{ marginTop: 5 }}
                    testID="password-input"
                  />

                  <Button
                    disabled={isSigningIn}
                    loading={isSigningIn}
                    mode="contained"
                    onPress={passwordLoginClicked}
                    style={{ marginTop: 5 }}
                    testID="password-login-button"
                  >
                    Login
                  </Button>
                </>
              ) : (
                <Button
                  disabled={isSigningIn}
                  loading={isSigningIn}
                  mode="contained"
                  onPress={sendMagicLink}
                  testID="magic-link-button"
                >
                  Send magic link
                </Button>
              )}

              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <Divider style={styles.signUpDivider} />
                <Text style={styles.signUpDividerText} variant="labelMedium">
                  OR
                </Text>
                <Divider style={styles.signUpDivider} />
              </View>
              <Button
                mode="outlined"
                onPress={() => Snackbar.info('Sign up is not yet implemented')}
                testID="sign-up-button"
              >
                Sign Up
              </Button>
            </SlideTransition>
            <SlideTransition
              active={otpAlreadySent}
              direction="right"
              isPrimary={false}
              style={styles.transitionContainer}
            >
              <Text variant="titleSmall">
                If the email you provided matches an existing account, we'll
                send a temporary code to that address.
              </Text>
              <Text
                style={styles.otpErrorText}
                testID="otp-error-message"
                variant="bodyMedium"
              >
                {otpError || ' '}
              </Text>
              <TextInput
                accessibilityHint="Enter the login code sent to your email"
                accessibilityLabel="Login Code"
                inputMode="numeric"
                label="Enter your login code"
                mode="outlined"
                onChangeText={onOtpChange}
                ref={otpInputRef}
                testID="otp-input"
                textContentType="oneTimeCode"
                value={otp}
              />
              <Button
                disabled={isValidatingOtp}
                loading={isValidatingOtp}
                mode="contained"
                onPress={handleOTPInput}
                testID="otp-button"
              >
                Sign in
              </Button>
              <Button
                mode="outlined"
                onPress={async () => {
                  await clearLoginAttemptInfo()
                  setOtpAlreadySent(false)
                }}
                testID="otp-back-button"
              >
                Back
              </Button>
            </SlideTransition>
          </View>
          <Text style={styles.legalText}>
            By creating an account, you agree to our{' '}
            <Text style={styles.legalTextLink}>Terms of Service</Text> and{' '}
            <Text style={styles.legalTextLink}>Privacy Policy</Text>
          </Text>
        </View>
      </Card>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  cardContainer: {
    alignContent: 'center',
    maxWidth: 400,
    minHeight: 500,
    minWidth: 350,
    overflow: 'hidden',
    padding: 30,
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  innerCard: {
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    marginTop: 10,
  },
  legalText: {
    alignContent: 'center',
    color: theme.colors.outline,
  },
  legalTextLink: {
    color: theme.colors.outline,
    textDecorationLine: 'underline',
  },
  logoStyle: {
    alignContent: 'center',
    flexBasis: 80,
    flexShrink: 1,
    justifyContent: 'center',
    marginBottom: 20,
  },
  otpErrorText: {
    alignContent: 'center',
    color: theme.colors.error,
    textAlign: 'center',
  },
  signUpDivider: {
    flex: 1,
    margin: 'auto',
    marginLeft: 20,
    marginRight: 20,
  },
  signUpDividerText: {
    alignContent: 'center',
    color: theme.colors.outline,
    justifyContent: 'center',
  },
  transitionContainer: {
    bottom: 0,
    flexDirection: 'column',
    height: '90%',
    justifyContent: 'space-between',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
}))

export default Login
