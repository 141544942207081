import AsyncStorage from '@react-native-async-storage/async-storage'
import { useGateValue } from '@statsig/expo-bindings'
import { router } from 'expo-router'
import { View } from 'moti'
import { memo } from 'react'
import { Platform } from 'react-native'
import {
  Button,
  Divider,
  Icon,
  Modal,
  Portal,
  Text,
  Tooltip,
  TouchableRipple,
} from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { AppVersions, Feature } from '../../../constants'
import useQueryClient from '../../hooks/useQueryClient'
import { useAppStore } from '../../store/useAppStore'
import { signOut } from '../../utils/auth/auth'
import trpc from '../../utils/trpc'
import Logo from './Logo'
import OrganizationButton from './OrganizationButton'
import UserAvatar from './UserAvatar'

interface Props {
  visible: boolean
  hideModal: () => void
}

const SettingsModal = ({ visible, hideModal }: Props) => {
  const { styles, theme } = useStyles(stylesheet)
  const isEmployee = useGateValue(Feature.Employee)

  const currentOrganization = useAppStore.use.currentOrganization().organization
  const setCurrentOrganization = useAppStore.use.setCurrentOrganization()

  const setAuth = useAppStore.use.setAuth()

  const queryClient = useQueryClient()

  const currentUserQuery = trpc.user.current.useQuery(
    {
      organizationId: currentOrganization?.id,
    },
    {
      enabled: !!currentOrganization?.id,
    }
  )

  const organizations = currentUserQuery.data?.organizations
    .map((org) => org.organization)
    .filter((org) => org.id !== currentOrganization?.id)

  const userFullName = `${currentUserQuery.data?.firstName} ${currentUserQuery?.data?.lastName}`

  return (
    <Portal>
      <Modal
        contentContainerStyle={styles.root}
        onDismiss={hideModal}
        visible={visible}
      >
        <View style={styles.logoContainer}>
          <Logo style={styles.logo} />
        </View>
        <View style={styles.innerContainer}>
          <View style={styles.rowContainer}>
            <UserAvatar
              size={40}
              style={styles.avatar}
              user={{
                emailHash: currentUserQuery.data?.emailHash ?? undefined,
                firstName: currentUserQuery.data?.firstName ?? '',
                lastName: currentUserQuery.data?.lastName ?? '',
              }}
            />
            <Text style={styles.title}>{userFullName}</Text>
          </View>
          <View style={styles.buttonRow}>
            <Button
              icon="account-edit"
              mode="outlined"
              onPress={() => {
                router.push(`/users/settings`)
                hideModal()
              }}
            >
              Settings
            </Button>
            <Button
              icon="logout"
              mode="outlined"
              onPress={async () => {
                await signOut()
                setAuth({
                  accessToken: undefined,
                  isLoggedIn: false,
                })

                // This clears all state used by supertokens, zustand, etc.
                await AsyncStorage.clear()

                // Clear all react-query state
                queryClient.clear()

                router.replace('/')
              }}
            >
              Sign Out
            </Button>
          </View>
          <OrganizationButton
            id={currentOrganization?.id || ''}
            logo={currentOrganization?.logo || null}
            name={currentOrganization?.name || ''}
          />
          <View style={styles.buttonRow}>
            <Button
              icon="account-multiple"
              mode="outlined"
              onPress={() => {
                router.push(`/organization/${currentOrganization?.id}/settings`)
                hideModal()
              }}
            >
              Manage Org
            </Button>
            {currentOrganization?.subscriptionActive ? (
              <Button
                icon="account-multiple"
                mode="outlined"
                onPress={() => {
                  router.push('/users')
                  hideModal()
                }}
              >
                Manage Users
              </Button>
            ) : (
              <Tooltip title="Requires active subscription">
                <Button
                  disabled
                  icon="account-multiple"
                  mode="outlined"
                  onPress={() => {
                    router.push('/users')
                    hideModal()
                  }}
                >
                  Manage Users
                </Button>
              </Tooltip>
            )}
          </View>
          <Divider
            style={{
              marginTop: theme.tokens.spacing[4],
            }}
          />
          {organizations?.map((org) => (
            <OrganizationButton
              id={org.id}
              key={org.id}
              logo={org.logo}
              name={org.name}
              onPress={() => {
                if (currentOrganization?.id !== org.id) {
                  setCurrentOrganization({
                    organization: {
                      id: org.id,
                      adoptionContract: org.adoptionContract,
                      logo: org.logo,
                      qrCode: org.qrCode,
                      name: org.name,
                    },
                  })
                }
                hideModal()
              }}
            />
          ))}
          <TouchableRipple
            accessibilityRole="button"
            borderless={true}
            onPress={() => {
              console.log('Not Implemented')
            }}
            style={[styles.rowContainer, { borderRadius: 16 }]}
          >
            <View
              style={{
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Icon size={40} source="account-multiple-plus-outline" />
              <Text style={{ marginLeft: 10 }}>Create Organization</Text>
            </View>
          </TouchableRipple>
        </View>
        <View
          style={{
            marginTop: theme.tokens.spacing[4],
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text style={styles.versionText}>
            {Platform.OS === 'web'
              ? AppVersions.expoVersion
              : AppVersions.nativeVersion}
            -{AppVersions.gitSha}
          </Text>
          {isEmployee && (
            <>
              <Text style={styles.versionText}>
                Git Sha: {AppVersions.gitSha}
              </Text>
              <Text style={styles.versionText}>
                Native Version: {AppVersions.nativeVersion}
              </Text>
              <Text style={styles.versionText}>
                JS Bundle Version: {AppVersions.expoVersion}
              </Text>
              {Platform.OS !== 'web' && (
                <Text style={styles.versionText}>
                  Runtime Version: {AppVersions.runtimeVersion?.slice(0, 9)}
                </Text>
              )}
            </>
          )}
        </View>
      </Modal>
    </Portal>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    avatar: {
      marginRight: theme.tokens.spacing[3],
    },
    buttonRow: {
      flexDirection: 'row',
      gap: theme.tokens.spacing[1],
      justifyContent: 'space-evenly',
    },
    innerContainer: {
      backgroundColor: theme.colors.surface,
      borderRadius: theme.tokens.containerBorderRadius * 2,
      gap: theme.tokens.spacing[2],
      padding: theme.tokens.spacing[3],
    },
    logo: {
      marginBottom: theme.tokens.spacing[4],
    },
    logoContainer: {
      alignItems: 'center',
    },
    root: {
      alignSelf: 'center',
      backgroundColor: theme.colors.surfaceVariant,
      borderRadius: theme.tokens.containerBorderRadius * 2,
      maxWidth: '90%',
      paddingHorizontal: theme.tokens.spacing[3],
      paddingVertical: theme.tokens.spacing[4],
    },
    rowContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      padding: theme.tokens.spacing[4],
    },
    title: {
      fontWeight: 'bold',
    },

    versionText: {
      color: theme.colors.onSurfaceDisabled,
    },
  }
})

export default memo(SettingsModal)
