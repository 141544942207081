import { router } from 'expo-router'
import { View } from 'react-native'
import { Text, TouchableRipple } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Species from '../../enums/species'
import { addAction, RumActionType } from '../../utils/datadog/datadog'
import FosterThumbnail from '../fosters/FosterThumbnail'
import { DashboardFoster } from './DashboardTypes'

interface Props {
  foster: DashboardFoster
  isOverdue: boolean
}

const EventCodeMap: Record<string, string> = {
  fecalFollowUp: 'Fecal Follow-Up',
  nextComboMed: 'Combo Med',
  nextFleaTickMed: 'Flea/Tick Prevention',
  nextHeartwormPrevention: 'Heartworm Prevention',
}

const DashboardEventCard = ({ foster, isOverdue }: Props) => {
  const { styles } = useStyles(stylesheet)

  return (
    <TouchableRipple
      accessibilityHint={`Opens the foster profile for ${foster.name}.`}
      accessibilityLabel={`${foster.name} - ${foster.events[0].dueType}`}
      accessibilityRole="button"
      borderless
      onPress={() => {
        addAction({
          type: RumActionType.TAP,
          name: `View - Foster`,
        })
        router.push(`/fosters/${foster.fosterId}`)
      }}
      style={styles.root}
    >
      <View style={styles.cardContainer}>
        <FosterThumbnail
          blurhash={foster.blurhash}
          key={`${foster.fosterId + foster.events[0]}-image`}
          // @ts-expect-error - species enum matching the API is an ongoing problem
          species={foster.species || Species.Dog}
          style={styles.fosterAvatar}
          thumbUri={foster.thumbUri}
        />
        <View style={styles.card}>
          <View>
            <Text style={styles.fosterName}>{foster.name}</Text>
            {foster.events.map((event) => (
              <View key={event.dueType}>
                <Text style={styles.eventName}>
                  {EventCodeMap[event.dueType] || event.dueType}
                </Text>
                {isOverdue && (
                  <Text style={styles.overdueText}>
                    was due {event.dueDate.toLocaleDateString('en-US')}
                  </Text>
                )}
              </View>
            ))}
          </View>
        </View>
      </View>
    </TouchableRipple>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    card: {
      backgroundColor: theme.colors.surface,
      borderColor: theme.colors.cardBorder,
      borderRadius: theme.tokens.containerBorderRadius,
      borderWidth: 1,
      flex: 1,
      paddingLeft: 50,
      paddingVertical: theme.tokens.spacing[3],
      zIndex: -1,
    },
    cardContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'space-between',
      marginLeft: 22,
    },
    eventName: {
      paddingTop: theme.tokens.spacing[2],
    },
    fosterAvatar: {
      borderRadius: 100,
      height: 60,
      left: -25,
      position: 'absolute',
      top: -2,
      width: 60,
    },
    fosterName: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    overdueText: {
      color: theme.colors.subtitle,
      fontSize: 12,
    },
    root: {
      borderRadius: theme.tokens.containerBorderRadius,
      padding: theme.tokens.spacing[2],
    },
  }
})

export default DashboardEventCard
