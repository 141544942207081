import { View } from 'react-native'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import UserService from '../../../services/userService'
import NotFound from '../../shared/NotFound'
import Skeleton from '../../shared/Skeleton'
import UserEmail from './settings/UserEmail'
import UserProfile from './settings/UserProfile'

const UserSettingsForm = () => {
  const { styles } = useStyles(stylesheet)

  const { user, isLoading } = UserService.useCurrentUser()

  if (!isLoading && !user) return <NotFound />

  return (
    <Skeleton.Group show={isLoading}>
      <View style={styles.cardContainer}>
        <UserProfile
          buttonMode="contained"
          buttonStyle={styles.button}
          formContainerStyle={styles.formContainer}
        />
        <UserEmail
          buttonMode="contained"
          buttonStyle={styles.button}
          formContainerStyle={styles.formContainer}
        />
      </View>
    </Skeleton.Group>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  button: {
    marginTop: theme.tokens.spacing[2],
    width: 200,
  },
  cardContainer: {
    flexDirection: {
      lg: 'row',
    },
    gap: theme.tokens.spacing[4],
  },
  formContainer: {
    flexDirection: {
      md: 'row',
    },
    gap: theme.tokens.spacing[4],
  },
}))

export default UserSettingsForm
