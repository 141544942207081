import { useIsMutating } from '@tanstack/react-query'
import { getQueryKey } from '@trpc/react-query'
import { useLocalSearchParams } from 'expo-router'
import { useMemo } from 'react'
import useQueryClient from '../hooks/useQueryClient'
import { useAppStore } from '../store/useAppStore'
import trpc from '../utils/trpc'

const FosterService = {
  useDocument() {
    const params = useLocalSearchParams<{ id: string }>()
    const fosterId = params.id || ''

    const currentOrganization =
      useAppStore.use.currentOrganization().organization

    const fosterQuery = trpc.foster.byId.useQuery({
      id: fosterId,
      organizationId: currentOrganization?.id || '',
    })

    const { data: foster, isLoading } = fosterQuery

    return { foster, isLoading, currentOrganization }
  },

  useDeleteFoster() {
    const { fosterId } = FosterService.useCurrentFoster()
    const { refreshFosterList } = FosterService.useFosterList()

    const fosterDeleteMutation = trpc.foster.delete.useMutation()

    const deleteFoster = async () => {
      await fosterDeleteMutation.mutateAsync({
        id: fosterId,
      })

      return refreshFosterList()
    }

    return {
      deleteFoster,
      isDeleting: fosterDeleteMutation.isLoading,
    }
  },

  useCurrentFoster() {
    const { id: fosterId } = useLocalSearchParams<{ id: string }>()

    return { fosterId }
  },

  useCurrentFosterName() {
    const { fosterId } = FosterService.useCurrentFoster()
    const { foster } = FosterService.useFoster(fosterId)
    const { fosterList } = FosterService.useFosterList()

    const { name } = foster || fosterList.find((f) => f.id === fosterId) || {}

    return name
  },

  useFoster(id: string) {
    const currentOrganization =
      useAppStore.use.currentOrganization().organization
    const queryClient = useQueryClient()

    const fosterQuery = trpc.foster.byId.useQuery({
      id,
      organizationId: currentOrganization?.id || '',
    })

    const { data: foster, isLoading, isSuccess, refetch } = fosterQuery

    return {
      foster,
      isLoading,
      isSuccess,
      refreshFoster: () => {
        queryClient.invalidateQueries(getQueryKey(trpc.foster.byId, { id }))
        return refetch()
      },
    }
  },

  useFosterList() {
    const currentOrganization =
      useAppStore.use.currentOrganization().organization
    const basicFilters = useAppStore.use.basicFilters()
    const advancedFilters = useAppStore.use.advancedFilters()

    const queryClient = useQueryClient()

    const fosterListQuery = trpc.foster.list.useInfiniteQuery(
      {
        advancedFilters:
          advancedFilters.isActive && advancedFilters.filters
            ? advancedFilters.filters
            : undefined,
        basicFilters: basicFilters.isActive ? basicFilters.filters : undefined,
        limit: 50,
        organizationId: currentOrganization?.id || '',
      },
      {
        keepPreviousData: true,
        getNextPageParam: (lastPage) => lastPage.nextCursor,
      }
    )

    const { data: pages, isLoading, fetchNextPage, refetch } = fosterListQuery

    const fosterList = useMemo(() => {
      return pages?.pages.flatMap((page) => page.items) || []
    }, [pages])

    return {
      fetchNextPage,
      fosterList,
      isLoading,
      refreshFosterList: () => {
        queryClient.invalidateQueries(getQueryKey(trpc.foster.list))
        return refetch()
      },
      totalFosters: fosterListQuery.data?.pages[0].totalFosters || 0,
    }
  },
  useIsSaving() {
    const mutationKey = getQueryKey(trpc.foster.update)
    return useIsMutating({ mutationKey }) > 0
  },
}

export default FosterService
