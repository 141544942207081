import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Card from '../../../components/shared/Card'
import Page from '../../../components/shared/Page'
import UserSettingsForm from '../../../components/users/detail/UserSettingsForm'

const UserSettings = () => {
  const { styles } = useStyles(stylesheet)

  return (
    <Page viewId="user-settings-page">
      <Card style={styles.card} title="Settings">
        <UserSettingsForm />
      </Card>
    </Page>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  card: {
    padding: theme.tokens.spacing[4],
  },
}))

export default UserSettings
