import {
  createMaterialTopTabNavigator,
  MaterialTopTabNavigationEventMap,
  MaterialTopTabNavigationOptions,
} from '@react-navigation/material-top-tabs'
import { ParamListBase, TabNavigationState } from '@react-navigation/native'
import { withLayoutContext } from 'expo-router'
import { Icon } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import FosterActionButton from '../../../../components/fosters/FosterActionButton'
import { FosterFormProvider } from '../../../../components/fosters/FosterFormContext'
import FosterService from '../../../../services/fosterService'
import { useAppStore } from '../../../../store/useAppStore'

const { Navigator } = createMaterialTopTabNavigator()

export const MaterialTopTabs = withLayoutContext<
  MaterialTopTabNavigationOptions,
  typeof Navigator,
  TabNavigationState<ParamListBase>,
  MaterialTopTabNavigationEventMap
>(Navigator)

const TabLayout = () => {
  const { styles, theme } = useStyles(stylesheet)

  const { fosterId } = FosterService.useCurrentFoster()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  if (!currentOrganization?.id) {
    return null
  }

  return (
    <FosterFormProvider>
      <MaterialTopTabs
        backBehavior="none"
        screenOptions={{
          animationEnabled: true,
          lazy: true,
          swipeEnabled: true,
          tabBarStyle: styles.tabBarStyle,
          sceneStyle: { backgroundColor: theme.colors.background },
        }}
        style={{ backgroundColor: theme.colors.background }}
      >
        <MaterialTopTabs.Screen
          initialParams={{ id: fosterId }}
          name="index"
          options={{
            tabBarActiveTintColor: theme.colors.primary,
            tabBarInactiveTintColor: theme.colors.secondary,
            tabBarIndicatorStyle: styles.tabBarIndicatorStyle,
            tabBarLabel: 'Profile',
            tabBarLabelStyle: styles.tabBarLabelStyle,
            tabBarIcon: (props) => {
              return Icon({
                ...props,
                size: theme.tokens.tabBarIconSize,
                source: 'information-outline',
              })
            },
            tabBarButtonTestID: 'profile-tab',
          }}
        />
        <MaterialTopTabs.Screen
          initialParams={{ id: fosterId }}
          name="health"
          options={{
            tabBarActiveTintColor: theme.colors.primary,
            tabBarInactiveTintColor: theme.colors.secondary,
            tabBarIndicatorStyle: styles.tabBarIndicatorStyle,
            tabBarLabel: 'Health',
            tabBarLabelStyle: styles.tabBarLabelStyle,
            tabBarIcon: (props) => {
              return Icon({
                ...props,
                size: theme.tokens.tabBarIconSize,
                source: 'needle',
              })
            },
            tabBarButtonTestID: 'health-tab',
          }}
        />
        <MaterialTopTabs.Screen
          initialParams={{ id: fosterId }}
          name="adoptions"
          options={{
            tabBarActiveTintColor: theme.colors.primary,
            tabBarInactiveTintColor: theme.colors.secondary,
            tabBarIndicatorStyle: styles.tabBarIndicatorStyle,
            tabBarLabel: 'Adoptions',
            tabBarLabelStyle: styles.tabBarLabelStyle,
            tabBarIcon: (props) => {
              return Icon({
                ...props,
                size: theme.tokens.tabBarIconSize,
                source: 'emoticon-kiss-outline',
              })
            },
            tabBarButtonTestID: 'adoptions-tab',
          }}
        />
        <MaterialTopTabs.Screen
          initialParams={{ id: fosterId }}
          name="documents"
          options={{
            tabBarActiveTintColor: theme.colors.primary,
            tabBarInactiveTintColor: theme.colors.secondary,
            tabBarIndicatorStyle: styles.tabBarIndicatorStyle,
            tabBarLabel: 'Documents',
            tabBarLabelStyle: styles.tabBarLabelStyle,
            tabBarIcon: (props) => {
              return Icon({
                ...props,
                size: theme.tokens.tabBarIconSize,
                source: 'file-document-multiple-outline',
              })
            },
            tabBarButtonTestID: 'documents-tab',
          }}
        />
      </MaterialTopTabs>
      <FosterActionButton
        currentOrganizationId={currentOrganization.id}
        fosterId={fosterId}
      />
    </FosterFormProvider>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    tabBarIndicatorStyle: {
      backgroundColor: theme.colors.primary,
    },
    tabBarLabelStyle: {
      fontSize: 10,
    },
    tabBarStyle: {
      backgroundColor: theme.colors.background,
      shadowColor: theme.colors.shadow,
      shadowRadius: theme.tokens.spacing[2],
    },
  }
})

export default TabLayout
