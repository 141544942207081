import { zodResolver } from '@hookform/resolvers/zod'
import { Skeleton } from 'moti/skeleton'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { StyleProp, View, ViewStyle } from 'react-native'
import { Button, ButtonProps, Text } from 'react-native-paper'
import { z } from 'zod'
import UserService from '../../../../services/userService'
import FormFieldText from '../../../form/FormFieldText'
import NotFound from '../../../shared/NotFound'

interface Props {
  buttonStyle?: StyleProp<ViewStyle>
  buttonMode?: ButtonProps['mode']
  formContainerStyle?: StyleProp<ViewStyle>
}

const UserProfile = ({
  buttonStyle,
  buttonMode,
  formContainerStyle,
}: Props) => {
  const { user, isLoading, currentOrganization } = UserService.useCurrentUser()

  const updateMutation = UserService.useUpdateMutation()

  const defaultValues = { ...user }

  const form = useForm<FormData>({
    // mode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const {
    control,
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
    reset,
  } = form

  useEffect(() => {
    reset(user)
  }, [reset, user])

  const onSubmit = async (data: FormData) => {
    if (!currentOrganization?.id) {
      return
    }

    updateMutation.mutate(data, {
      onSuccess: (updatedData) => {
        reset({ ...updatedData })
      },
    })
  }

  if (!isLoading && !user) return <NotFound />

  return (
    <Skeleton.Group show={isLoading}>
      <Text variant="titleLarge">Basic Profile</Text>
      <View>
        <View style={formContainerStyle}>
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={errors}
            fieldName="firstName"
            isLoading={isLoading}
            label="First Name"
            required={true}
            style={{ flex: 2 }}
          />
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={errors}
            fieldName="lastName"
            isLoading={isLoading}
            label="Last Name"
            required={true}
            style={{ flex: 2 }}
          />
        </View>
        <Button
          disabled={isSubmitting || !isDirty}
          mode={buttonMode}
          onPress={handleSubmit(onSubmit)}
          style={buttonStyle}
        >
          Save
        </Button>
      </View>
    </Skeleton.Group>
  )
}

const formSchema = z.object({
  id: z.string().uuid(),
  firstName: z.string().min(1, { message: 'Required' }),
  lastName: z.string().min(1, { message: 'Required' }),
})

type FormData = z.infer<typeof formSchema>

export default UserProfile
