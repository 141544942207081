import * as DocumentPicker from 'expo-document-picker'
import { DragDropContentView } from 'expo-drag-drop-content-view'
import * as FileSystem from 'expo-file-system'
import { router, useLocalSearchParams } from 'expo-router'
import pQueue from 'p-queue/dist/index.js'
import { useCallback, useEffect, useState } from 'react'
import { Alert, Platform, View } from 'react-native'
import { ActivityIndicator, Button, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { uuidv7 } from 'uuidv7'
import useFile from '../../../hooks/useFile'
import FosterService from '../../../services/fosterService'
import { useAppStore } from '../../../store/useAppStore'
import styles from '../../../styles/styles'
import trpc from '../../../utils/trpc'
import Card from '../../shared/Card'
import Document from './Document'

const uploadQueue = new pQueue({ concurrency: 20 })

interface Props {
  disabled: boolean
}

const FosterDocumentsCard = ({ disabled }: Props) => {
  const { styles } = useStyles(stylesheet)

  const { scanUris } = useLocalSearchParams<{ scanUris: string | string[] }>()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const { fosterId } = FosterService.useCurrentFoster()
  const { foster, refreshFoster } = FosterService.useFoster(fosterId)
  const [pendingDocuments, setPendingDocuments] = useState<
    | {
        [index: number]: {
          totalBytes: number
          bytesUploaded: number
        }
      }
    | undefined
  >()

  const { scanFiles, createUploadTasks } = useFile()

  const documentCreateMutation = trpc.foster.documents.create.useMutation()
  const documentDeleteMutation = trpc.foster.documents.delete.useMutation()

  const hasDocuments = !!foster?.documents?.length

  useEffect(() => {
    if (!foster?.documents.filter((doc) => !doc.analysisVersion).length) {
      return
    }

    const timer = setInterval(() => {
      refreshFoster()
    }, 2000)

    return () => clearInterval(timer)
  }, [foster?.documents, refreshFoster])

  const progressCallback = useCallback(
    (progress: FileSystem.UploadProgressData, index: number) => {
      setPendingDocuments((prev) => ({
        ...prev,
        [index]: {
          totalBytes: progress.totalBytesExpectedToSend,
          bytesUploaded: progress.totalBytesSent,
        },
      }))
    },
    [setPendingDocuments]
  )

  const handleDocuments = useCallback(
    async (
      files: {
        fileName: string
        fileUri: string
      }[]
    ) => {
      if (!files.length) {
        return
      }

      const uploadTasks = createUploadTasks({
        files,
        progressCallback: progressCallback,
      })

      const newPendingDocuments: {
        [index: number]: {
          totalBytes: number
          bytesUploaded: number
        }
      } = {}

      uploadTasks?.forEach((task, index) => {
        newPendingDocuments[index] = {
          totalBytes: 100,
          bytesUploaded: 0,
        }
      })

      setPendingDocuments(newPendingDocuments)

      const fileIds = await Promise.all(
        uploadTasks.map((task) =>
          uploadQueue.add(async () => {
            const response = await task.uploadAsync()
            const json = JSON.parse(response?.body ?? '{}') as {
              fileId: string
            }

            if (!json.fileId) {
              throw new Error('No fileId returned from upload')
            }

            return json.fileId
          })
        )
      ).then((results) =>
        results.filter((id): id is string => id !== undefined)
      )

      if (fileIds?.length) {
        await documentCreateMutation.mutateAsync(
          fileIds.map((fileId) => ({
            fosterId,
            fileId,
            type: 'Uncategorized',
          }))
        )

        setPendingDocuments(undefined)

        await refreshFoster()
      }
    },
    [
      createUploadTasks,
      documentCreateMutation,
      fosterId,
      progressCallback,
      refreshFoster,
      setPendingDocuments,
    ]
  )

  const scanDocuments = useCallback(async () => {
    const uris = await scanFiles()
    handleDocuments(
      uris?.map((uri) => ({
        fileName: `${uuidv7()}.jpg`,
        fileUri: uri,
      })) ?? []
    )
  }, [handleDocuments, scanFiles])

  const selectDocuments = useCallback(async () => {
    const result = await DocumentPicker.getDocumentAsync({
      type: ['application/pdf', 'image/*'],
      multiple: true,
    })

    if (!result.assets?.length) {
      return
    }

    if (
      result.assets?.filter((asset) => asset.mimeType?.startsWith('image'))
        .length
    ) {
      Alert.alert(
        'Images Detected',
        'We recommend either uploading a PDF, or using the Scan Documents feature.',
        [
          {
            text: 'Upload Anyway',
            onPress: async () => {
              handleDocuments(
                result.assets.map((asset) => ({
                  fileName: asset.name,
                  fileUri: asset.uri,
                }))
              )
            },
          },
          {
            text: 'Scan Documents',
            style: 'cancel',
            onPress: scanDocuments,
          },
        ]
      )
    } else {
      handleDocuments(
        result.assets.map((asset) => ({
          fileName: asset.name,
          fileUri: asset.uri,
        }))
      )
    }
  }, [handleDocuments, scanDocuments])

  useEffect(() => {
    async function handleScanUris() {
      if (scanUris && !pendingDocuments) {
        const uris = Array.isArray(scanUris) ? scanUris : scanUris.split(',')
        router.replace(`/fosters/${fosterId}/documents`)

        await handleDocuments(
          uris.map((uri) => ({
            fileName: `${uuidv7()}.jpg`,
            fileUri: uri,
          }))
        )
      }
    }

    handleScanUris()
  }, [fosterId, handleDocuments, pendingDocuments, scanUris])

  if (!currentOrganization) {
    return null
  }

  // group the documents by type in a nested array
  let documentsByType = Object.values(
    foster?.documents.reduce<Record<string, typeof foster.documents>>(
      (acc, document) => {
        // @ts-expect-error - TODO: fix this
        document.type = document.analysisVersion
          ? document.type
          : 'NewDocuments'
        const type = document.type
        if (!acc[type]) {
          acc[type] = []
        }
        acc[type].push(document)
        return acc
      },
      {}
    ) ?? {}
  )

  // Filter documents alphabetically by type, but put unidentified documents first
  documentsByType = documentsByType.sort((a, b) => {
    // Check if either group has unidentified documents
    const aHasUnidentified = a.some((doc) => !doc.analysisVersion)
    const bHasUnidentified = b.some((doc) => !doc.analysisVersion)

    // Put unidentified documents first
    if (aHasUnidentified && !bHasUnidentified) return -1
    if (!aHasUnidentified && bHasUnidentified) return 1

    // If both are identified or both unidentified, sort by type
    return a[0].type.localeCompare(b[0].type)
  })

  return (
    <DragDropContentView
      onDrop={async (f) => {
        const files = f.assets
          .map((file) => {
            if (!file.fileName || !file.uri) {
              return undefined
            }

            return {
              fileName: file.fileName,
              fileUri: file.uri,
            }
          })
          .filter(Boolean) as { fileName: string; fileUri: string }[]

        handleDocuments(files)
      }}
    >
      <Card style={styles.root} title="Documents">
        {!hasDocuments && <Text>No documents uploaded.</Text>}
        {pendingDocuments && (
          <View style={styles.pendingDocumentsCard}>
            <Text>
              Uploading {Object.keys(pendingDocuments).length} documents
            </Text>
            <ActivityIndicator />
          </View>
        )}
        {hasDocuments &&
          documentsByType.map((documents, index) => {
            return (
              <View
                style={index > 0 ? styles.documentTypeContainer : undefined}
              >
                <Text variant="titleLarge">
                  {documents[0].type.split(/(?=[A-Z])/).join(' ')}
                </Text>
                <View style={styles.documentCardContainer}>
                  {hasDocuments &&
                    documents.map((document) => {
                      return (
                        <Document
                          createdAt={document.file.createdAt}
                          detailShort={document.detailShort ?? undefined}
                          documentId={document.id}
                          fileId={document.file.id}
                          fileName={`${foster.name}'s ${document.type} ${document.file.createdAt.toLocaleDateString(
                            'en-US',
                            {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                            }
                          )}.${document.file.name.split('.').pop()}`}
                          mimeType={document.file.mimeType}
                          onDelete={() => {
                            documentDeleteMutation.mutate(
                              {
                                id: document.id,
                                organizationId: currentOrganization.id,
                              },
                              {
                                onSuccess: () => {
                                  refreshFoster()
                                },
                              }
                            )
                          }}
                          onUpdate={() => {
                            refreshFoster()
                          }}
                          testID={`document-list-item-${index}`}
                          uploadedBy={document.file.uploadedBy}
                        />
                      )
                    })}
                </View>
              </View>
            )
          })}
        <View style={styles.buttonRow}>
          {Platform.OS !== 'web' && (
            <Button
              disabled={disabled}
              icon={'scanner'}
              mode="contained"
              onPress={scanDocuments}
            >
              Scan Documents
            </Button>
          )}
          <Button
            disabled={disabled}
            icon={'upload'}
            mode="outlined"
            onPress={selectDocuments}
          >
            Upload Documents
          </Button>
        </View>
      </Card>
    </DragDropContentView>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    buttonRow: styles(theme).buttonRow,
    documentCardContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '16',
    },
    documentTypeContainer: {
      marginTop: theme.tokens.spacing[4],
    },
    pendingDocumentsCard: {
      alignItems: 'center',
      backgroundColor: theme.colors.background,
      borderColor: theme.colors.cardBorder,
      borderRadius: theme.tokens.containerBorderRadius,
      borderWidth: 1,
      flex: 1,
      flexDirection: 'row',
      gap: theme.tokens.spacing[4],
      justifyContent: 'space-between',
      padding: theme.tokens.spacing[4],
    },
    root: {
      backgroundColor: theme.colors.background,
      borderWidth: 0,
      padding: 0,
    },
  }
})

export default FosterDocumentsCard
