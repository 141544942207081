import { datadogRum } from '@datadog/browser-rum'
import { GestureResponderEvent } from 'react-native'
import { AppVersions } from '../../../constants'

export enum RumActionType {
  SCROLL = 'SCROLL',
  TAP = 'TAP',
  SWIPE = 'SWIPE',
  BACK = 'BACK',
  CUSTOM = 'CUSTOM',
}

export async function enableSessionReplay() {}

export async function evaluateFeature(feature: string, evaluation: any) {
  datadogRum.addFeatureFlagEvaluation(feature, evaluation)
}

export async function initialize() {
  datadogRum.init({
    allowedTracingUrls: [
      'https://api.rescuebase.dev',
      /https:\/\/.*rescuebase2\.fly\.dev.*/,
    ],
    applicationId: process.env.EXPO_PUBLIC_WEB_DATADOG_APPLICATION_ID || '',
    clientToken: process.env.EXPO_PUBLIC_WEB_DATADOG_CLIENT_TOKEN || '',
    enableExperimentalFeatures: ['feature_flags'],
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'rescuebase-web',
    env: process.env.DD_ENV || process.env.NODE_ENV || 'local',
    version: `${AppVersions.expoVersion}-${AppVersions.gitSha}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    traceSampleRate: 100,
  })
}

export function setUser(user: {
  id: string
  email: string
  firstName: string
  lastName: string
}) {
  datadogRum.setUser({
    email: user.email,
    id: user.id,
    name: `${user.firstName} ${user.lastName}`,
  })
}

export function stop() {
  datadogRum.setTrackingConsent('not-granted')
}

export function trackPageView(_viewkey: string, _pathname: string) {
  //noop for web
}

export function addAction({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type,
  name,
  context,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  timestampMs,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionContext,
}: {
  type: RumActionType
  name: string
  context?: object
  timestampMs?: number
  actionContext?: GestureResponderEvent
}) {
  datadogRum.addAction(name, context)
}

export function startAction({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type,
  name,
  context,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  timestampMs,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionContext,
}: {
  type: RumActionType
  name: string
  context?: object
  timestampMs?: number
  actionContext?: GestureResponderEvent
}) {
  datadogRum.addAction(name, context)
}

export function stopAction({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  name,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  context,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  timestampMs,
}: {
  type: RumActionType
  name: string
  context?: object
  timestampMs?: number
}) {
  // noop for web
}
